import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { seo, intro, main } from '../content/depoty'
import Layout from '../components/Layout'
import Intro from '../components/Intro'
import { useLangContext } from '../context/lang.context'
import Seo from '../components/Seo'
import Main from '../components/Main'
import Tiles from '../components/Tiles'

const Depots = () => {
  const { lang } = useLangContext()
  const { image, children } = useStaticQuery(graphql`
    {
      image: file(absolutePath: { regex: "/images/intros/spedycja.jpg/" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(
            quality: 90
            placeholder: DOMINANT_COLOR
            formats: [AUTO,WEBP]
          )
        }
      }
      children: allContentfulDepot(sort: { fields: name }) {
        nodes {
          name
          slug
          lang {
            slug
          }
          noCreatePage
          image {
            gatsbyImageData(width: 480, quality: 90, formats: [AUTO,WEBP])
          }
        }
      }
    }
  `)

  const links = children.nodes
    .filter((node) => node.lang.slug === lang)
    .filter((node) => node.slug != null)
    .filter((node) => !node.noCreatePage)
    .map((node) => ({
      slug: node.slug,
      name: node.name,
      image: node.image,
    }))

  return (
    <Layout>
      <Seo
        title={seo.title[lang]}
        desc={seo.desc[lang]}
        image={'images/spedycja.jpg'}
        url={seo.url}
      />
      <Intro data={intro} image={image.childImageSharp} />
      <Main data={main} h={1} />
      <Tiles data={links} />
    </Layout>
  )
}

export default Depots
