export const seo = {
  url: 'depoty',
  title: {
    pl: 'Kontenery Morskie | Depoty Kontenerów',
    en: 'Forwarding services | Best Forwarding',
  },
  desc: {
    pl: `✓ Kontenery Morskie ✓ Kontenery 20'DV ✓ Kontener 40'DV ✓Sklep z kontenerami ✓Szybka realizacja`,
    en: 'Check what the forwarding does. Over 20 specialized forwarding branches of Omida Logistics throughout Poland, ready to provide transport and logistics services.',
  },
  keywords: [
    'omida',
    'logistics',
    'spedycja',
    'usługi spedycyjne',
    'polska spedycja',
  ],
}

export const intro = {
  title: {
    pl: 'Depoty',
    en: 'Depots',
  },
  desc: {
    pl: 'Kontenery dopasowane do specyfiki danej branży. Stawiamy na szybką realizację i dużą dostępność.',
    en: 'Check what the forwarding does. Over 20 specialized forwarding branches of Omida Logistics throughout Poland, ready to provide transport and logistics services.',
  },
  button: {
    text: {
      pl: 'Czytaj więcej',
      en: 'Read more',
    },
    action: 'SCROLL',
  },
}

export const main = {
  body: [
    {
      headline: {
        pl: 'Co to jest spedycja? Definicja spedycji.',
        en: 'What is forwarding? Definition of freight forwarding.',
      },
      texts: [
        {
          pl: '<span>Spedycja to przede wszystkim <strong>ludzie</strong> i cały <strong>proces przemieszczenia ładunku</strong> (w tym transport, przeładunek i magazynowanie) przy wykorzystaniu dostępnych środków transportu drogowego, morskiego, lotniczego i kolejowego, w wyniku którego następuje <strong>dostarczenie towaru</strong> klienta do miejsca docelowego.</span>',
          en: '<span>Forwarding is primarily <strong>people</strong> and the entire <strong>process of cargo movement</strong> (including transport, handling and storage) using the available means of road, sea, air and rail transport, in the result of which is <strong> delivery of the goods </strong> of the customer to the destination.</span>',
        },
      ],
    },
    {
      headline: {
        pl: 'Ponad 30 oddziałów spedycyjnych w całej Polsce',
        en: 'Over 20 forwarding branches all over Poland',
      },
      texts: [
        {
          pl: 'Nasze oddziały znajdują się we wszystkich województwach w Polsce. Przeczytaj więcej o naszych oddziałach i dowiedz się, w czym się specjalizują.',
          en: 'Our branches are located in all voivodships in Poland. Read more about our divisions and find out what they specialize in.',
        },
      ],
    },
  ],
}
